
// State
export const state = () => ({
	loading: false,
	cms: [],
	menus: [],
	jackpotVal: -1,
})

// Actions
export const actions = {
	// Get pages
	async cms({ commit, rootState }, params) {
		commit('setLoading', true)
		const theme = this.$config.THEME
		await this.$axios
			.get(`/cms/lnd/component-contents?currency=${rootState.settings.defaultCurrency}&theme_code=${theme}`)
			.then((response) => {
				// commit('cms', response?.data?.components) // old format
				commit('cms', response?.data)
				const cms = response?.data
				const data = cms?.find((object) => object.component_code === 'JackpotContent');
				if (data && data.time_interval && data.is_visible) {
					// setJackpotInterval(commit, this, 1)
					setJackpotUnlimitedTimeInterval(commit, this, 1) // 'this' is global variable (Can access global vuex store, cookie, axios, moment...)
				} else {
					commit('setJackpotVal', 0)
				}
				commit('setLoading', false)
			})
			.catch(() => false)
	},
	async getFavicon({ _ }) {
		const theme = this.$config.THEME;
		// Since This API woks only in the Server Side, we don't need to call it by ingress
		let path = `/cms/lnd/favicon?theme_code=${theme}`
		if (this.$config.PRODUCTION && process.server) {
			path = this.$config.CMS_SERVER_URL + path
		}

		const response = await this.$axios
			.get(path, {})
			.then((response) => {
				if (response.data?.image_path) {
					return response.data?.image_path
				}
			})
			.catch((_) => {
				return false
			})

		return response;
	},
	async getMenubars({ commit, rootState }, params) {
		const theme = this.$config.THEME
		const response = await this.$axios(`cms/lnd/menubar-contents?currency=${rootState.settings.defaultCurrency}&theme_code=${theme}`)
			.then((data) => {
				commit("setMenubars", data?.data)
			})
			.catch(_ => {
				return false;
			})
		return response
	},
}

// Mutations
export const mutations = {
	cms(state, data) {
		state.cms = data
	},
	setLoading: (state, type) => {
		state.loading = type
	},
	setJackpotVal: (state, val) => {
		state.jackpotVal = val
	},
	setMenubars(state, data) {
		state.menus = data
	}
}

// Getters
export const getters = {
	getCMS: (state) => (input) => {
		const _res = state?.cms?.length > 0
			? state?.cms?.find((object) => object.component_code === input)
			: ''
		return _res;
	},
	jackpotVal: state => state.jackpotVal,
	menubars: (state) => {
		if (state.menus.length) {
			const menus = JSON.parse(JSON.stringify(state?.menus));
			const sortedMenubars = menus.sort((a, b) =>
				parseFloat(a.sequence) - parseFloat(b.sequence)
			)
			try {
				const subItemSortedMenubarsObject = JSON.parse(JSON.stringify(sortedMenubars))
				subItemSortedMenubarsObject.forEach(menu => {
					menu?.items.sort((a, b) => a.sequence - b.sequence);
				})
				return subItemSortedMenubarsObject || [];
			} catch (error) {
				return sortedMenubars || []
			}
		}
		return []
		// else {
		// Find menubar component_code = Menubar1 - Menubar9
		// 	const menubars = state?.cms?.filter((object) => object?.component_code?.startsWith('Menubar'))
		// 	const sortedMenubars = menubars.sort((a, b) =>
		// 		parseFloat(a.sequence) - parseFloat(b.sequence)
		// 	)
		// 	try {
		// 		const subItemSortedMenubarsObject = JSON.parse(JSON.stringify(sortedMenubars))
		// 		subItemSortedMenubarsObject.forEach(menu => {
		// 			menu?.items.sort((a, b) => a.sequence - b.sequence);
		// 		})
		// 		return subItemSortedMenubarsObject || [];
		// 	} catch (error) {
		// 		return sortedMenubars || []
		// 	}
		// }
	},
	faviconIcon: (state) => {
		const logo = state?.cms?.find((object) => object.component_code === 'Favicon')
		return logo || {};
	},
	headerLogo: (state) => {
		const logo = state?.cms?.find((object) => object.component_code === 'HeaderLogo')
		return logo || {};
	},
	desktopBackground: (state) => {
		const data = state?.cms?.find((object) => object.component_code === 'DesktopBackground')
		return data || {};
	},
	desktopSlider: (state) => {
		const data = state?.cms?.find((object) => object.component_code === 'SliderContentDesktop')
		return data || {};
	},
	mobileBackground: (state) => {
		const data = state?.cms?.find((object) => object.component_code === 'MobileBackground')
		return data || {};
	},
	mobileSlider: (state) => {
		const data = state?.cms?.find((object) => object.component_code === 'SliderContentMobile')
		return data || {};
	},
	rightFloatingSupportIcon: (state) => {
		const data = state?.cms?.find((object) => object.component_code === 'FloatingIcon1')
		return data || {};
	},
	rightFloatingMessageIcon: (state) => {
		const data = state?.cms?.find((object) => object.component_code === 'FloatingIcon2')
		return data || {};
	},
	holdBalanceInstruction: (state) => {
		const data = state?.cms?.find((object) => object.component_code === 'OnHoldBalanceInstructionalContent')
		return data || {};
	},
	depositInstruction: (state) => {
		const data = state?.cms?.find((object) => object.component_code === 'DepositInstructionContent')
		return data || {};
	},
	introductionVideoContents: (state) => {
		// Find video content component_code = VideoContent1 - VideoContent2
		const data = state?.cms?.filter((object) => object?.component_code?.startsWith('VideoContent'))
		return data.sort((a, b) => a?.component_code?.localeCompare(b?.component_code)) || [];
	},
	introductionVideoContent1: (state) => {
		const data = state?.cms?.find((object) => object.component_code === 'VideoContent1')
		return data || {};
	},
	introductionVideoContent2: (state) => {
		const data = state?.cms?.find((object) => object.component_code === 'VideoContent2')
		return data || {};
	},
	lotteryWinner: (state) => {
		const data = state?.cms?.find((object) => object.component_code === 'DesktopLotteryWinners')
		return data || {};
	},
	topDepositRanking: (state) => {
		const data = state?.cms?.find((object) => object.component_code === 'TopDepositRanking')
		return data || {};
	},
	featuredContent1: (state) => {
		const data = state?.cms?.find((object) => object.component_code === 'FeaturedContent1')
		return data || {};
	},
	featuredContent2: (state) => {
		const data = state?.cms?.find((object) => object.component_code === 'FeaturedContent2')
		return data || {};
	},
	featuredContent3: (state) => {
		const data = state?.cms?.find((object) => object.component_code === 'FeaturedContent3')
		return data || {};
	},
	jackpot: (state) => {
		const data = state?.cms?.find((object) => object.component_code === 'JackpotContent')
		return data || {};
	},
	promotion: (state) => {
		const data = state?.cms?.find((object) => object.component_code === 'PromotionContent')
		return data || {};
	},

	recentWinner: (state) => {
		const data = state?.cms?.find((object) => object.component_code === 'RecentWinners')
		return data || {};
	},
	topWinner: (state) => {
		const data = state?.cms?.find((object) => object.component_code === 'TopWinners')
		return data || {};
	},
	unluckyWinner: (state) => {
		const data = state?.cms?.find((object) => object.component_code === 'UnluckyWinners')
		return data || {};
	},
	footerLogo: (state) => {
		const logo = state?.cms?.find((object) => object.component_code === 'FooterLogo')
		return logo || {};
	},
	footerPartnerIcon: (state) => {
		const logo = state?.cms?.find((object) => object.component_code === 'PartnerIcon')
		return logo || {};
	},
	detailArticles: (state) => {
		// Find article content component_code = ArticleContent1 -ArticleContent3
		const data = []
		const article1 = state?.cms?.find((object) => object.component_code === 'ArticleContent1')
		const article2 = state?.cms?.find((object) => object.component_code === 'ArticleContent2')
		const article3 = state?.cms?.find((object) => object.component_code === 'ArticleContent3')
		const termAndCondition = state?.cms?.find((object) => object.component_code === 'TermsAndConditions')
		if (article1) data.push(article1)
		if (article2) data.push(article2)
		if (article3) data.push(article3)
		if (termAndCondition) data.push(termAndCondition)
		return data;
	},
	detailArticlesTheme3: (state) => {
		// Find article content component_code = ArticleContent1 -ArticleContent3
		const data = []
		const article1 = state?.cms?.find((object) => object.component_code === 'ArticleContent1')
		const article2 = state?.cms?.find((object) => object.component_code === 'ArticleContent2')
		const termAndCondition = state?.cms?.find((object) => object.component_code === 'TermsAndConditions')
		if (article1 && article1?.is_visible) data.push(article1)
		if (article2 && article2?.is_visible) data.push(article2)
		if (termAndCondition && termAndCondition?.is_visible) data.push(termAndCondition)
		return data;
	},
	footerArticle1: (state) => {
		const data = state?.cms?.find((object) => object.component_code === 'ArticleContent1')
		return data || {};
	},
	footerArticle2: (state) => {
		const data = state?.cms?.find((object) => object.component_code === 'ArticleContent2')
		return data || {};
	},
	footerArticle3: (state) => {
		const data = state?.cms?.find((object) => object.component_code === 'ArticleContent3')
		return data || {};
	},
	footerArticle4: (state) => {
		const data = state?.cms?.find((object) => object.component_code === 'ArticleContent4')
		return data || {};
	},
	footerGamingInfo: (state) => {
		const data = state?.cms?.find((object) => object.component_code === 'GamingInformation')
		return data || {};
	},
	footerTermAndCondition: (state) => {
		const data = state?.cms?.find((object) => object.component_code === 'TermsAndConditions')
		return data || {};
	},
	splashScreen: (state) => {
		const data = state?.cms?.find((object) => object.component_code === 'DesktopSplashScreen')
		return data || {};
	},
	registrationImage: (state) => {
		const data = state?.cms?.find((object) => object.component_code === 'RegistrationImage')
		return data || {};
	},
	bannerDeposit: (state) => {
		const data = state?.cms?.find((object) => object.component_code === 'BannerDeposit')
		return data || {};
	},
	announcement: (state) => {
		const data = state?.cms?.find((object) => object.component_code === 'Announcement')
		return data || {};
	},
}
/* function setJackpotMinuteInterval(commit, store, randomTime) {
	setTimeout(() => {
		const timeInterval = store.getters['cms/jackpot'].time_interval;
		const timeMinuteInterval = timeInterval * 60 - 1
		const minAmount = store.getters['cms/jackpot'].min_jackpot_amount;
		const maxAmount = store.getters['cms/jackpot'].max_jackpot_amount;
		const time = new Date();
		const hour = time.getHours();
		const minute = (hour * 60) + time.getMinutes()
		const distanceVal = maxAmount - minAmount
		const valPerMinute = Math.floor(distanceVal / timeMinuteInterval)
		let distanceTimeMinute = 0
		if (minute < timeMinuteInterval) {
			distanceTimeMinute = minute
		} else {
			distanceTimeMinute = minute - timeMinuteInterval
		}
		const jVal = minute === 0 ? minAmount : minute === timeMinuteInterval ? maxAmount : minAmount + (valPerMinute * distanceTimeMinute)
		commit('setJackpotVal', jVal)
		const randomMinute = Math.floor(Math.random() * 180000)
		setJackpotInterval(commit, store, randomMinute)
	}, randomTime)
} */

/* function setJackpotInterval(commit, store, randomTime) { // Limited interval round only 24 or 12
	setTimeout(() => {
		const timeInterval = store.getters['cms/jackpot'].time_interval > 24 ? 24 : store.getters['cms/jackpot'].time_interval;
		const minAmount = store.getters['cms/jackpot'].min_jackpot_amount;
		const maxAmount = store.getters['cms/jackpot'].max_jackpot_amount;
		const timeSecondInterval = (timeInterval * 60 - 1) * 60 - 1
		const time = new Date();
		const hour = time.getHours();
		const second = (hour * 60 * 60) + time.getSeconds()
		const distanceVal = maxAmount - minAmount
		const valPerSecond = Math.floor(distanceVal / timeSecondInterval)
		let distanceTimeSecond = 0
		if (second < timeSecondInterval) {
			distanceTimeSecond = second
		} else {
			distanceTimeSecond = second - timeSecondInterval
		}
		const jVal = second === 0 ? minAmount : second === timeSecondInterval ? maxAmount : minAmount + (valPerSecond * distanceTimeSecond)
		commit('setJackpotVal', jVal)
		const randomSecond = Math.floor(getRandomTimeoutNumber())
		setJackpotInterval(commit, store, randomSecond)
	}, randomTime)
} */

function getRandomTimeoutNumber() {
	const randomDecimal = Math.random();
	const randomNumber = Math.floor(randomDecimal * (3000 - 1000 + 1)) + 1000;
	return randomNumber
}
function getJVal(hourNotIncludeCurrentDay, maxAmount, minAmount, timeSecondInterval, timeInterval, extraTime = null) {
	/* ------------- Get time data of current day ------------- */
	let currentDateTime = null;
	if (!extraTime) {
		currentDateTime = new Date();
	} else {
		currentDateTime = new Date(new Date().getTime() + extraTime);
	}

	const currentHours = currentDateTime.getHours();
	const currentMinutes = currentDateTime.getMinutes();
	/* ------------- Get time data of current day ------------- */

	const unFullRound = (hourNotIncludeCurrentDay + currentHours) % timeInterval // Calculate the hours of unfull round
	/*
		1. if unFullRound = 0 that mean just finish a round and continue to new round
		2. if unFullRound > 0 the current round is going on and the value of unFullRound is the hours of current round that is passed
	*/

	const second = ((unFullRound * 60 + currentMinutes) * 60 - 1) + currentDateTime.getSeconds() // calculate the passed seconds
	const distanceVal = maxAmount - minAmount	// calculate distance value between min and max
	const valPerSecond = Math.floor(distanceVal / timeSecondInterval) // Calculate value per second

	const jVal = second <= 6 ? minAmount : second >= (timeSecondInterval - 6) ? maxAmount : minAmount + (valPerSecond * second) // Calculate jacpot display value
	/*
		1. second less than 6s ( second <= 6 ) display min jackpot value from setting
		2. second less than max second of current round about 6s ( second >= (timeSecondInterval - 6) ) display max jackpot value from setting
		3. Another second just calculate to find out the suitable random jackpot value then display to client
		{ *note: if not set less or greater than like condition 1 and 2 sometime program would skip display min and max jackpot value }
	 */
	return jVal
}
function setJackpotUnlimitedTimeInterval(commit, global, randomTime) { // Unlimited time interval setting value
	setTimeout(async () => {

		/* ------------- Declare jackpot data ------------- */
		const timeInterval = global.getters['cms/jackpot'].time_interval;
		const minAmount = global.getters['cms/jackpot'].min_jackpot_amount;
		const maxAmount = global.getters['cms/jackpot'].max_jackpot_amount;
		const intervalStartDate = global.getters['cms/jackpot'].updated_at;
		/* ------------- Declare jackpot data ------------- */

		/* ------------- Calculate all duration days from start setting jactpot until current day ------------- */
		const $moment = global.$moment
		const duration = $moment.duration(
			$moment($moment().local().format('YYYY-MM-DD')).local().diff(
				intervalStartDate ?
					$moment(intervalStartDate).local().format('YYYY-MM-DD') :
					$moment().local().format('YYYY-MM-DD')
			)
		)
		const durationDays = duration.asDays()
		/* ------------- Calculate all duration days from start setting jactpot until current day ------------- */

		const hourNotIncludeCurrentDay = durationDays * 24; // Calculate all hours of the duration days that is not including current day hours

		const timeSecondInterval = (timeInterval * 60) * 60 - 1 // Calculate all seconds of the jackpot time interval


		const jVal = getJVal(hourNotIncludeCurrentDay, maxAmount, minAmount, timeSecondInterval, timeInterval, null)

		const randomSecond = Math.floor(getRandomTimeoutNumber()) // find randome second between 1s - 3s
		await asyncOperation('i', randomSecond)
		setJackpotUnlimitedTimeInterval(commit, global, randomSecond * 2) // Set next jackpot random

		const nextJVal = getJVal(hourNotIncludeCurrentDay, maxAmount, minAmount, timeSecondInterval, timeInterval, randomSecond * 2)
		const diffJVal = nextJVal - jVal

		const internalTimeForEach = randomSecond / diffJVal
		for (let i = 0; i < diffJVal; i++) {
			const currentJackpotVal = global.state.cms.jackpotVal;
			if (jVal + i > currentJackpotVal) {
				commit('setJackpotVal', jVal + i) // Set jackpot value to store
			}
			await asyncOperation(i, internalTimeForEach);

		}
	}, randomTime)
}
function asyncOperation(item, timeoutTime) {
	return new Promise(resolve => {
		setTimeout(() => {
			resolve(item);
		}, timeoutTime);
	});
}
